import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "header-language-select"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
    key: 1,
    class: "dropdown ml-auto"
};
const _hoisted_4 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.theme.languages && _ctx.theme.languages.length === 2)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.theme.languages, (lang) => {
                return (_openBlock(), _createElementBlock("li", {
                    onClick: ($event) => (_ctx.changeLang(lang)),
                    key: lang.locale,
                    class: _normalizeClass([{ selected: _ctx.i18n.global.locale === lang.locale }, 'lang-link-' + lang.locale])
                }, _toDisplayString(lang.languageCode), 11, _hoisted_2));
            }), 128))
        ]))
        : (_ctx.theme.languages && _ctx.theme.languages.length >= 3)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", {
                    class: "language-btn",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.showDropdown()))
                }, _toDisplayString(_ctx.selectedLanguage(_ctx.theme.languages, _ctx.i18n.global.locale)), 1),
                _createElementVNode("div", {
                    id: "dropdown-menu",
                    class: _normalizeClass(["dropdown-menu language-btn-dropdown fade", { show: _ctx.show }])
                }, [
                    _createElementVNode("ul", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.theme.languages, (lang) => {
                            return (_openBlock(), _createElementBlock("li", {
                                class: _normalizeClass(["text-uppercase", [{ selected: _ctx.i18n.global.locale === lang.locale }, 'lang-link-' + lang.locale]]),
                                onClick: ($event) => (_ctx.changeLang(lang)),
                                key: lang.locale
                            }, _toDisplayString(lang.fullName), 11, _hoisted_4));
                        }), 128))
                    ])
                ], 2)
            ]))
            : _createCommentVNode("", true);
}
