import axios from 'axios';
import { Environment } from '@/services/environment';
import cookies from '@/cookies';
export const OrderService = {
    getOrder: function (id) {
        return axios.get(`${Environment.ORDER_API}/orders/${id}`, {});
    },
    payOrder(properties) {
        const order = properties.order;
        if (!order) {
            throw new Error('Order can\'t be null for paying');
        }
        const backUrl = window.location.href;
        return axios.post(`${Environment.ORDER_API}/orders/${order.id}/pay`, {
            amount: {
                amount: properties.amount,
                currency: order.amount.currency
            },
            locale: cookies.cookies.get('locale'),
            countryCode: properties.theme.countryCode,
            attributes: properties.attributes,
            callbackCancel: backUrl + '?success=false',
            callbackSuccess: backUrl + '?success=true',
            callbackPending: backUrl + '?success=pending',
            provider: properties.provider
        });
    }
};
