import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = ["data-theme"];
const _hoisted_2 = {
    key: 0,
    class: "justify-content-center"
};
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "gray-container" };
const _hoisted_5 = { class: "container" };
const _hoisted_6 = { class: "row justify-content-center" };
const _hoisted_7 = { class: "col-12 col-md-10" };
const _hoisted_8 = { class: "gray-inner-container" };
const _hoisted_9 = { class: "row justify-content-center h-100" };
const _hoisted_10 = { class: "col-12 col-sm-10 h-100" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Spinner = _resolveComponent("Spinner");
    const _component_cookie_consent = _resolveComponent("cookie-consent");
    const _component_order_header = _resolveComponent("order-header");
    return (_openBlock(), _createElementBlock("div", {
        id: "app",
        "data-theme": _ctx.currentProperties.order ? _ctx.currentProperties.order.theme : 'default'
    }, [
        (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_Spinner)
            ]))
            : _createCommentVNode("", true),
        (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_cookie_consent),
                _createVNode(_component_order_header, {
                    paymentReference: _ctx.currentProperties.order ? _ctx.currentProperties.order.paymentReference : null,
                    title: _ctx.currentProperties.order ? _ctx.currentProperties.order.details : null,
                    clientName: _ctx.currentProperties.order ? _ctx.currentProperties.order.clientName : null,
                    theme: _ctx.currentProperties.theme
                }, null, 8, ["paymentReference", "title", "clientName", "theme"]),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("div", _hoisted_9, [
                                        _createElementVNode("div", _hoisted_10, [
                                            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentView), _normalizeProps(_guardReactiveProps(_ctx.currentProperties)), null, 16))
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ])
                ])
            ]))
            : _createCommentVNode("", true)
    ], 8, _hoisted_1));
}
