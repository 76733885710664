export const CookieService = {
    getCookie(name) {
        const cookies = document.cookie.split('; ');
        const cookieName = name + '=';
        let c;
        for (let i = 0; i < cookies.length; i += 1) {
            c = cookies[i].replace(/^\s\+/g, '');
            if (c.indexOf(cookieName) === 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    },
    setCookieDate(name, value, expireDate, path = '/') {
        const expires = 'expires=' + expireDate.toUTCString();
        document.cookie = name + '=' + value + '; ' + expires + '; domain=' + this.getTopDomain() + '; path=' + path;
    },
    getTopDomain() {
        const domainPartsReversed = location.hostname.split('.').reverse();
        return domainPartsReversed[1] + '.' + domainPartsReversed[0];
    }
};
