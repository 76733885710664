import axios from 'axios';
import { Environment } from '@/services/environment';
export const PaymentService = {
    getProviders: function (merchant) {
        return axios.get(`${Environment.PAYMENTS_API}/payments/providers?merchant=${merchant}`);
    }
};
export default class PaymentProvider {
    id;
    type;
    countryCode;
    constructor(id, type, countryCode) {
        this.id = id;
        this.type = type;
        this.countryCode = countryCode;
    }
}
export var ProviderType;
(function (ProviderType) {
    ProviderType[ProviderType["ONLINE_BANK"] = 0] = "ONLINE_BANK";
    ProviderType[ProviderType["ONLINE_SERVICE"] = 1] = "ONLINE_SERVICE";
    ProviderType[ProviderType["STATIC_BANK"] = 2] = "STATIC_BANK";
})(ProviderType || (ProviderType = {}));
