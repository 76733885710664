import { createApp } from 'vue';
import App from '@/app.vue';
import VueCookies from 'vue3-cookies';
import { Environment } from '@/services/environment';
import VueMatomo from 'vue-matomo';
import router from '@/router';
import i18n from './i18n';
const app = createApp(App)
    .use(router)
    .use(VueCookies)
    .use(i18n);
app.use(VueMatomo, {
    host: Environment.PIWIK_URL,
    siteId: Environment.PIWIK_ID,
    router: router,
    requireConsent: false,
    trackInitialView: true
});
app.config.warnHandler = function (msg, vm, trace) {
    return null;
};
window.addEventListener('load', function () {
});
router.isReady().then(() => {
    app.mount('#app');
});
