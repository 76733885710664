import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "cookie-consent-container",
    role: "form"
};
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "row cookie-consent" };
const _hoisted_4 = { class: "col-lg-9 cookie-text" };
const _hoisted_5 = ["href"];
const _hoisted_6 = { class: "col-lg-3 cookie-button clearfix" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("p", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t("COOKIE_CONSENT_TEXT")) + " ", 1),
                            _createElementVNode("a", {
                                href: _ctx.$t('COOKIE_POLICY_LINK'),
                                target: "_blank",
                                class: "link-dark"
                            }, _toDisplayString(_ctx.$t("COOKIE_CONSENT_LINK_TEXT")), 9, _hoisted_5),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t("COOKIE_CONSENT_CONTINUE")), 1)
                        ]),
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t("COOKIES_CONSENT_SECONDARY")), 1)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("a", {
                            class: "btn btn-secondary cookie-consent-accept",
                            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.acceptCookieConsent($event)))
                        }, _toDisplayString(_ctx.$t("COOKIE_CONSENT_ACCEPT")), 1)
                    ])
                ])
            ])
        ]))
        : _createCommentVNode("", true);
}
