const defaultLanguage = {
    locale: 'en',
    fullName: 'English'
};
const defaultTheme = {
    loginButton: false,
    preselectProvider: false,
    languages: [defaultLanguage]
};
export const ThemeService = {
    settings: {},
    getThemeSettings(theme) {
        return this.settings[theme] || defaultTheme;
    },
    getDefaultLanguage(themeSettings) {
        return themeSettings.languages ? themeSettings.languages[0] : defaultLanguage;
    },
    loadThemes() {
        return import('@/themes.json').then(module => {
            this.settings = module.default;
        });
    }
};
export default class ThemeSettings {
    loginButton;
    preselectProvider;
    loginButtonTranslation;
    loginButtonLink;
    countryCode;
    numberDecimalSeparator;
    numberThousandSeparator;
    languages;
    constructor(loginButton, preselectProvider, loginButtonTranslation, loginButtonLink, countryCode, numberDecimalSeparator, numberThousandSeparator, languages) {
        this.loginButton = loginButton;
        this.preselectProvider = preselectProvider;
        this.loginButtonTranslation = loginButtonTranslation;
        this.loginButtonLink = loginButtonLink;
        this.countryCode = countryCode;
        this.numberDecimalSeparator = numberDecimalSeparator;
        this.numberThousandSeparator = numberThousandSeparator;
        this.languages = languages;
    }
}
