import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "spinner-container text-center" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("div", {
            class: "spinner-border",
            role: "status"
        }, [
            _createElementVNode("span", { class: "sr-only" }, "Loading...")
        ], -1)
    ])));
}
