export default class Order {
    amount;
    details;
    id;
    merchant;
    payLink;
    status;
    theme;
    shortPortfolioId;
    isPiSegmentCountry;
    constructor(amount, details, id, merchant, payLink, status, theme, shortPortfolioId, isPiSegmentCountry) {
        this.amount = amount;
        this.details = details;
        this.id = id;
        this.merchant = merchant;
        this.payLink = payLink;
        this.status = status;
        this.theme = theme;
        this.shortPortfolioId = shortPortfolioId;
        this.isPiSegmentCountry = isPiSegmentCountry;
    }
}
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["OPEN"] = "OPEN";
    OrderStatus["PROCESSING"] = "PROCESSING";
    OrderStatus["PAID"] = "PAID";
    OrderStatus["EXPIRED"] = "EXPIRED";
})(OrderStatus || (OrderStatus = {}));
