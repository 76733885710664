import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "error-block" };
const _hoisted_2 = { class: "error-list" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { class: "input-group flex-nowrap" };
const _hoisted_5 = ["id", "value"];
const _hoisted_6 = ["for"];
const _hoisted_7 = {
    key: 0,
    class: "row"
};
const _hoisted_8 = { class: "col-6" };
const _hoisted_9 = { class: "input-group additional-input" };
const _hoisted_10 = {
    for: "mobile",
    class: "overlap"
};
const _hoisted_11 = { class: "form-text text-muted" };
const _hoisted_12 = { key: 0 };
const _hoisted_13 = { class: "input-group flex-nowrap" };
const _hoisted_14 = ["id", "value"];
const _hoisted_15 = ["for"];
const _hoisted_16 = {
    key: 0,
    class: "row"
};
const _hoisted_17 = { class: "col-6" };
const _hoisted_18 = { class: "input-group additional-input" };
const _hoisted_19 = {
    for: "mobile",
    class: "overlap"
};
const _hoisted_20 = { class: "form-text text-muted" };
const _hoisted_21 = { key: 0 };
const _hoisted_22 = { class: "input-group flex-nowrap" };
const _hoisted_23 = ["id", "value"];
const _hoisted_24 = ["for"];
const _hoisted_25 = {
    key: 0,
    class: "row"
};
const _hoisted_26 = { class: "col-6" };
const _hoisted_27 = { class: "input-group additional-input" };
const _hoisted_28 = {
    for: "mobile",
    class: "overlap"
};
const _hoisted_29 = { class: "form-text text-muted" };
const _hoisted_30 = { class: "primary-button-block text-right" };
const _hoisted_31 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("a", {
            href: "#",
            class: "text-link previous-link",
            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.backToDetails()))
        }, "< " + _toDisplayString(_ctx.$t('BACK')), 1),
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('PAYMENT_OPTION_TITLE')), 1),
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("ul", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorCodes, (errorCode) => {
                    return (_openBlock(), _createElementBlock("li", { key: errorCode }, _toDisplayString(_ctx.$t(errorCode)), 1));
                }), 128))
            ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (providers, type) => {
            return (_openBlock(), _createElementBlock("div", { key: type }, [
                ((!_ctx.isPiSegment && type === 'STATIC_BANK'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t(`PAYMENT_OPTION_${type}`)), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(providers, (provider) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: provider.id
                            }, [
                                _createElementVNode("div", _hoisted_4, [
                                    _withDirectives(_createElementVNode("input", {
                                        id: provider.id,
                                        value: provider.id,
                                        type: "radio",
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.selectedProvider) = $event))
                                    }, null, 8, _hoisted_5), [
                                        [_vModelRadio, _ctx.selectedProvider]
                                    ]),
                                    _createElementVNode("label", {
                                        for: provider.id
                                    }, _toDisplayString(_ctx.$t('PROVIDER_' + provider.id)), 9, _hoisted_6)
                                ]),
                                (provider.id === 'swish' && _ctx.selectedProvider === 'swish')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                        _createElementVNode("div", _hoisted_8, [
                                            _createElementVNode("div", _hoisted_9, [
                                                _withDirectives(_createElementVNode("input", {
                                                    type: "text",
                                                    id: "mobile",
                                                    class: _normalizeClass(["form-control", { filled: _ctx.attributes.phoneNumber !== '' }]),
                                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.attributes.phoneNumber) = $event))
                                                }, null, 2), [
                                                    [_vModelText, _ctx.attributes.phoneNumber]
                                                ]),
                                                _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('MOBILE_NUMBER')), 1),
                                                _createElementVNode("small", _hoisted_11, _toDisplayString(_ctx.$t('SWISH_ORDER_HELP_TEXT')), 1)
                                            ])
                                        ])
                                    ]))
                                    : _createCommentVNode("", true)
                            ]));
                        }), 128))
                    ]))
                    : _createCommentVNode("", true)
            ]));
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (providers, type) => {
            return (_openBlock(), _createElementBlock("div", { key: type }, [
                ((_ctx.isPiSegment && type === 'ONLINE_SERVICE'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t(`PAYMENT_OPTION_${type}`)), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(providers, (provider) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: provider.id
                            }, [
                                _createElementVNode("div", _hoisted_13, [
                                    _withDirectives(_createElementVNode("input", {
                                        id: provider.id,
                                        value: provider.id,
                                        type: "radio",
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.selectedProvider) = $event))
                                    }, null, 8, _hoisted_14), [
                                        [_vModelRadio, _ctx.selectedProvider]
                                    ]),
                                    _createElementVNode("label", {
                                        for: provider.id
                                    }, _toDisplayString(_ctx.$t('PROVIDER_' + provider.id)), 9, _hoisted_15)
                                ]),
                                (provider.id === 'swish' && _ctx.selectedProvider === 'swish')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                        _createElementVNode("div", _hoisted_17, [
                                            _createElementVNode("div", _hoisted_18, [
                                                _withDirectives(_createElementVNode("input", {
                                                    type: "text",
                                                    id: "mobile",
                                                    class: _normalizeClass(["form-control", { filled: _ctx.attributes.phoneNumber !== '' }]),
                                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.attributes.phoneNumber) = $event))
                                                }, null, 2), [
                                                    [_vModelText, _ctx.attributes.phoneNumber]
                                                ]),
                                                _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('MOBILE_NUMBER')), 1),
                                                _createElementVNode("small", _hoisted_20, _toDisplayString(_ctx.$t('SWISH_ORDER_HELP_TEXT')), 1)
                                            ])
                                        ])
                                    ]))
                                    : _createCommentVNode("", true)
                            ]));
                        }), 128))
                    ]))
                    : _createCommentVNode("", true)
            ]));
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (providers, type) => {
            return (_openBlock(), _createElementBlock("div", { key: type }, [
                ((_ctx.isPiSegment && type === 'ONLINE_BANK'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t(`PAYMENT_OPTION_${type}`)), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(providers, (provider) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: provider.id
                            }, [
                                _createElementVNode("div", _hoisted_22, [
                                    _withDirectives(_createElementVNode("input", {
                                        id: provider.id,
                                        value: provider.id,
                                        type: "radio",
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((_ctx.selectedProvider) = $event))
                                    }, null, 8, _hoisted_23), [
                                        [_vModelRadio, _ctx.selectedProvider]
                                    ]),
                                    _createElementVNode("label", {
                                        for: provider.id
                                    }, _toDisplayString(_ctx.$t('PROVIDER_' + provider.id)), 9, _hoisted_24)
                                ]),
                                (provider.id === 'swish' && _ctx.selectedProvider === 'swish')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                        _createElementVNode("div", _hoisted_26, [
                                            _createElementVNode("div", _hoisted_27, [
                                                _withDirectives(_createElementVNode("input", {
                                                    type: "text",
                                                    id: "mobile",
                                                    class: _normalizeClass(["form-control", { filled: _ctx.attributes.phoneNumber !== '' }]),
                                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.attributes.phoneNumber) = $event))
                                                }, null, 2), [
                                                    [_vModelText, _ctx.attributes.phoneNumber]
                                                ]),
                                                _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t('MOBILE_NUMBER')), 1),
                                                _createElementVNode("small", _hoisted_29, _toDisplayString(_ctx.$t('SWISH_ORDER_HELP_TEXT')), 1)
                                            ])
                                        ])
                                    ]))
                                    : _createCommentVNode("", true)
                            ]));
                        }), 128))
                    ]))
                    : _createCommentVNode("", true)
            ]));
        }), 128)),
        _createElementVNode("div", _hoisted_30, [
            (_ctx.selectedProvider !== '')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    disabled: !_ctx.isValid(),
                    class: "btn btn-primary",
                    onClick: _cache[7] || (_cache[7] = ($event) => (_ctx.submitPayment()))
                }, _toDisplayString(_ctx.$t('PAY_WITH')) + " " + _toDisplayString(_ctx.$t('PROVIDER_' + _ctx.selectedProvider)), 9, _hoisted_31))
                : _createCommentVNode("", true)
        ])
    ]));
}
