import axios from 'axios';
import { Environment } from '@/services/environment';
import i18n from '@/i18n';
import cookies from '@/cookies';
export const TranslationService = {
    loadedLanguages: [],
    getTranslations: function (lang) {
        const localeArr = lang.locale.split('-');
        const language = localeArr[0];
        const countryPrefix = localeArr[1] ? localeArr[1].toLowerCase() + '/' : '';
        return axios.get(`${Environment.TRANSLATION_API}/translations/${countryPrefix}${language}.json`);
    },
    setI18nLanguage: function (lang) {
        i18n.global.locale = lang.locale;
        cookies.cookies.set('locale', lang.locale);
        return lang.locale;
    },
    loadLanguageAsync: function (lang) {
        if (i18n.global.locale !== lang.locale) {
            if (!this.loadedLanguages.includes(lang.locale)) {
                return this.getTranslations(lang)
                    .then((response) => {
                    i18n.global.setLocaleMessage(lang.locale, response.data);
                    this.loadedLanguages.push(lang.locale);
                    return this.setI18nLanguage(lang);
                });
            }
            return Promise.resolve(this.setI18nLanguage(lang));
        }
        return Promise.resolve(lang.locale);
    }
};
export default class Language {
    fullName;
    locale;
    constructor(fullName, locale) {
        this.fullName = fullName;
        this.locale = locale;
    }
}
