<template>
  <div>
    <h2>{{  $t('ORDER_PROCESSING_TITLE')}}</h2>
    <p>{{  $t('ORDER_PROCESSING_DESCRIPTION')}}</p>

    <div class="row">
      <div class="col-6">
        <ul>
          <li><a href="#">Austria</a></li>
          <li><a href="#">Belgium</a></li>
          <li><a href="#">Czech Republic</a></li>
          <li><a href="#">Denmark</a></li>
          <li><a href="#">Estonia</a></li>
          <li><a href="#">Finland</a></li>
          <li><a href="#">France</a></li>
          <li><a href="#">Germany</a></li>
          <li><a href="#">Hungary</a></li>
          <li><a href="#">Ireland</a></li>
          <li><a href="#">Italy</a></li>
          <li><a href="#">Latvia</a></li>
        </ul>
      </div>
      <div class="col-6">
        <ul>
          <li><a href="#">Lithuania</a></li>
          <li><a href="#">Netherlands</a></li>
          <li><a href="#">Norway</a></li>
          <li><a href="#">Poland</a></li>
          <li><a href="#">Portugal</a></li>
          <li><a href="#">Romania</a></li>
          <li><a href="#">Slovakia</a></li>
          <li><a href="#">Spain</a></li>
          <li><a href="#">Sweden</a></li>
          <li><a href="#">Switzerland</a></li>
          <li><a href="#">United Kingdom</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup></script>
