import { Environment } from '@/services/environment';
class TrackerService {
    queue = [];
    constructor() {
        setInterval(() => this.queueHandler(), 1000);
    }
    queueHandler() {
        const piwik = window.Piwik;
        if (piwik) {
            const tracker = piwik.getTracker(Environment.PIWIK_URL + '/matomo.php', Environment.PIWIK_ID);
            while (this.queue && this.queue.length > 0) {
                const action = this.queue.pop();
                if (action) {
                    action(tracker);
                }
            }
        }
    }
    init(user, lang) {
        this.queue.push((tracker) => {
            tracker.setUserId(user);
            tracker.setCustomVariable(1, 'Language', lang, 'visit');
        });
    }
    pageView(title) {
        this.queue.push(tracker => {
            tracker.trackPageView(title);
        });
    }
    event(category, action) {
        this.queue.push(tracker => {
            tracker.trackEvent(category, action);
        });
    }
}
export const trackerServiceSingleton = new TrackerService();
export default trackerServiceSingleton;
