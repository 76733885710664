export const Environment = {
    BASE_HOST: process.env.VUE_APP_BASE_HOST,
    BASE_PATH: process.env.VUE_APP_BASE_PATH,
    BASE_URL: process.env.VUE_APP_BASE_HOST + process.env.VUE_APP_BASE_PATH,
    TRANSLATION_API: process.env.VUE_APP_TRANSLATION_API,
    PAYMENTS_API: process.env.VUE_APP_PAYMENTS_API,
    ERROR_REDIRECT_URL: process.env.VUE_APP_ERROR_REDIRECT_URL,
    ORDER_API: process.env.VUE_APP_ORDER_API,
    PIWIK_ID: process.env.VUE_APP_PIWIK_SITE_ID,
    PIWIK_URL: process.env.VUE_APP_PIWIK_URL
};
