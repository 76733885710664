import { createI18n } from 'vue-i18n';
import { ThemeService } from '@/services/theme-service';
import axios from 'axios';
import cookies from '@/cookies';
import { Environment } from '@/services/environment';
function loadLocaleMessages() {
    const messages = {};
    const lang = getLang();
    getTranslations(lang)
        .then((response) => {
        messages[lang.locale] = response.data;
    });
    return messages;
}
export default createI18n({
    locale: getLang().locale,
    fallbackLocale: getLang().locale,
    messages: loadLocaleMessages(),
    fallbackWarn: false,
    missingWarn: false
});
function getTranslations(lang) {
    const localeArr = lang.locale.split('-');
    const language = localeArr[0];
    const countryPrefix = localeArr[1] ? localeArr[1].toLowerCase() + '/' : '';
    return axios.get(`${Environment.TRANSLATION_API}/translations/${countryPrefix}${language}.json`);
}
function getLang() {
    const themeSettings = ThemeService.getThemeSettings('');
    return cookies.cookies.isKey('locale')
        ? parseLocale(cookies.cookies.get('locale'))
        : ThemeService.getDefaultLanguage(themeSettings);
}
function parseLocale(locale) {
    return {
        locale: locale,
        fullName: locale
    };
}
