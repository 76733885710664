import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "text-right" };
const _hoisted_2 = { class: "menu-container text-left" };
const _hoisted_3 = { class: "menu-options" };
const _hoisted_4 = { id: "menu-contact-us" };
const _hoisted_5 = ["href"];
const _hoisted_6 = { id: "menu-privacy-policy" };
const _hoisted_7 = ["href"];
const _hoisted_8 = { id: "menu-cookies-policy" };
const _hoisted_9 = ["href"];
const _hoisted_10 = { id: "menu-gdpr" };
const _hoisted_11 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", {
            class: "icon-navigation",
            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.open()))
        }),
        _createElementVNode("div", {
            class: _normalizeClass(["main-menu", { active: _ctx.isOpen }]),
            onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.close())),
            style: _normalizeStyle({ display: _ctx.isDisplayed ? 'block' : 'none' })
        }, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "fifth-element" }, null, -1)),
            _createElementVNode("div", _hoisted_2, [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "logo" }, [
                    _createElementVNode("div", { class: "logo-image" })
                ], -1)),
                _createElementVNode("ul", _hoisted_3, [
                    _createElementVNode("li", _hoisted_4, [
                        _createElementVNode("a", {
                            class: "h4",
                            target: "_blank",
                            href: _ctx.$t('PUBLIC_CONTACT_US_URL')
                        }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('PUBLIC_CONTACT_US')), 1)
                        ], 8, _hoisted_5)
                    ]),
                    _createElementVNode("li", _hoisted_6, [
                        _createElementVNode("a", {
                            class: "h4",
                            target: "_blank",
                            href: _ctx.$t('PUBLIC_PRIVACY_POLICY_URL')
                        }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('PUBLIC_PRIVACY_POLICY')), 1)
                        ], 8, _hoisted_7)
                    ]),
                    _createElementVNode("li", _hoisted_8, [
                        _createElementVNode("a", {
                            class: "h4",
                            target: "_blank",
                            href: _ctx.$t('PUBLIC_COOKIES_POLICY_URL')
                        }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('PUBLIC_COOKIES_POLICY')), 1)
                        ], 8, _hoisted_9)
                    ]),
                    _createElementVNode("li", _hoisted_10, [
                        _createElementVNode("a", {
                            class: "h4",
                            target: "_blank",
                            href: _ctx.$t('PUBLIC_GDPR_URL')
                        }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('PUBLIC_GDPR')), 1)
                        ], 8, _hoisted_11)
                    ])
                ]),
                _createElementVNode("span", {
                    class: "icon-close",
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.close()))
                })
            ])
        ], 6)
    ]));
}
