import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '../../assets/images/intrum.svg';
const _hoisted_1 = { class: "container logo web-navbar" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-8 col-lg-8 header-elements" };
const _hoisted_4 = { class: "hero" };
const _hoisted_5 = { class: "container" };
const _hoisted_6 = { class: "payment" };
const _hoisted_7 = {
    key: 0,
    class: "description client-name"
};
const _hoisted_8 = {
    key: 1,
    class: "title"
};
const _hoisted_9 = {
    key: 2,
    class: "description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_main_menu = _resolveComponent("main-menu");
    const _component_header_language_switcher = _resolveComponent("header-language-switcher");
    return (_openBlock(), _createElementBlock("header", null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-4 col-lg-4" }, [
                    _createElementVNode("div", { class: "logo-image" }, [
                        _createElementVNode("img", { src: _imports_0 })
                    ])
                ], -1)),
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_main_menu, { theme: _ctx.theme }, null, 8, ["theme"]),
                    _createVNode(_component_header_language_switcher, { theme: _ctx.theme }, null, 8, ["theme"])
                ])
            ])
        ]),
        _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h2", null, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('PAYMENT')), 1),
                    (_ctx.clientName)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.clientName), 1))
                        : _createCommentVNode("", true),
                    (_ctx.paymentReference)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('PAYMENT_FOR_CASE')) + " " + _toDisplayString(_ctx.paymentReference), 1))
                        : (_ctx.title)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.title), 1))
                            : _createCommentVNode("", true)
                ])
            ])
        ])
    ]));
}
