import { createRouter, createWebHistory } from 'vue-router';
import { Environment } from '@/services/environment';
import GenericError from '@/components/generic-error.vue';
import App from '@/app.vue';
const routes = [
    {
        path: '/',
        component: GenericError
    },
    {
        path: '/:hash',
        component: App,
        params: true
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }
];
const router = createRouter({
    history: createWebHistory(Environment.BASE_PATH),
    routes
});
export default router;
