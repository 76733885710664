import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "input-group flex-nowrap" };
const _hoisted_2 = ["value"];
const _hoisted_3 = { for: "opt" };
const _hoisted_4 = { class: "primary-button-block text-right" };
const _hoisted_5 = ["disabled"];
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { key: 1 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('PAYMENT_DETAILS_TITLE')), 1),
        _createElementVNode("div", _hoisted_1, [
            _withDirectives(_createElementVNode("input", {
                id: "opt",
                value: _ctx.order.amount.amount,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.selectedAmount) = $event)),
                type: "radio"
            }, null, 8, _hoisted_2), [
                [_vModelRadio, _ctx.selectedAmount]
            ]),
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.formatAmount(_ctx.order.amount.amount)) + " " + _toDisplayString(_ctx.order.amount.currency), 1)
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
                type: "button",
                disabled: _ctx.selectedAmount === 0,
                class: "btn btn-primary",
                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.submitAmount()))
            }, [
                (!_ctx.preselectedProvider)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('PAYMENT_DETAILS_NEXT')), 1))
                    : _createCommentVNode("", true),
                (_ctx.preselectedProvider)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('PAY_WITH')) + " " + _toDisplayString(_ctx.$t('PROVIDER_' + _ctx.preselectedProvider.id)), 1))
                    : _createCommentVNode("", true)
            ], 8, _hoisted_5)
        ])
    ]));
}
