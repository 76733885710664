import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { key: 1 };
const _hoisted_3 = { key: 2 };
const _hoisted_4 = { key: 3 };
const _hoisted_5 = { key: 4 };
const _hoisted_6 = { key: 5 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_next_link = _resolveComponent("next-link");
    return (_openBlock(), _createElementBlock("div", null, [
        (_ctx.paymentSuccessful)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(_ctx.$t('PAYMENT_PROCESSING_TITLE')), 1))
            : _createCommentVNode("", true),
        (_ctx.paymentCancelled)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.$t('PAYMENT_FAILURE_HEADER')), 1))
            : _createCommentVNode("", true),
        (_ctx.paymentPending)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.$t('PAYMENT_PENDING_HEADER')), 1))
            : _createCommentVNode("", true),
        (_ctx.paymentSuccessful)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('PAYMENT_PROCESSING_DESCRIPTION')), 1))
            : _createCommentVNode("", true),
        (_ctx.paymentCancelled)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('PAYMENT_FAILURE')), 1))
            : _createCommentVNode("", true),
        (_ctx.paymentPending)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('PAYMENT_PENDING')), 1))
            : _createCommentVNode("", true),
        (_ctx.theme && _ctx.theme.loginButton)
            ? (_openBlock(), _createBlock(_component_next_link, {
                key: 6,
                translationKey: _ctx.theme.loginButtonTranslation,
                url: _ctx.theme.loginButtonLink
            }, null, 8, ["translationKey", "url"]))
            : _createCommentVNode("", true)
    ]));
}
