import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("p", null, [
        _createElementVNode("a", {
            href: _ctx.url,
            class: "next-link"
        }, [
            _createTextVNode(_toDisplayString(_ctx.$t(_ctx.translationKey)) + " ", 1),
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "icon-next-2" }, null, -1))
        ], 8, _hoisted_1)
    ]));
}
